import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal, Form } from "react-bootstrap";
import * as Styles from "../Users/style";
import LoadingOverlay from "react-loading-overlay";
import { BounceLoader } from "react-spinners";
import { MultiSelect } from "react-multi-select-component";
import close from "../assets/images/close.png";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Select from 'react-select'
import { AddFinancialProductForm } from "./AddFinancialProductForm";
import moment from 'moment'

const readCookie = require('../../cookie.js').readCookie
const currentToken = readCookie('access_token')
export const FinancialProduct = () => {
  const [Originaldata, setOriginaldata] = useState([]); // Stores the unfiltered data
  const [financialProduct, setfinancialProduct] = useState([]); // Stores the filtered data
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [type, setType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of records per page
  const [totalRecords, setTotalRecords] = useState(0); // Total records from API
  
  useEffect(() => {
    fetchProjects(currentPage, pageSize);
  }, [currentPage, pageSize]); // Refetch when page or limit changes
  

  const fetchProjects = (page = 1, limit = 10) => {
    setLoading(true);
    
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/humblebee/financial-product?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${JSON.parse(readCookie('access_token'))}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("Fetched Data:", data);
  
        setOriginaldata(data); // Assuming API returns { items: [...], total: 100 }
        setfinancialProduct(data);
        setTotalRecords(data.length || 0); // Set total count for pagination
        setLoading(false);
      })
      .catch(error => {
        console.error(error, 'Error fetching projects');
        setLoading(false);
      });
  };
  
  const paginationOptions = {
    totalSize: totalRecords, // Set total records count
    sizePerPage: pageSize,
    sizePerPageList: [10, 25, 50], // Page size options
    page: currentPage,
    onPageChange: (page) => setCurrentPage(page), // Change page
    onSizePerPageChange: (size) => {
      setPageSize(size);
      setCurrentPage(1); // Reset to first page when changing page size
    }
  };
  

  const handleShowModal = (type, FinancialProduct = null) => {
    console.log(type,FinancialProduct);
    if (FinancialProduct) {
      setModalData({
       ...FinancialProduct
      });
      setType(type)
    } else {
      setModalData({});
      setType(type)
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if(type!=='view'){
      fetchProjects()
      setType('')
    }
  };
  const formatCreatedAt = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return <span>-</span>
  }
  const formatBeekeeperCount = (cell, row) => {
    if (cell) {
      return '--'
    } else return <span>-</span>
  }

  const columns = [
    { dataField: "uniqueID", text: "ID" },
    {
      dataField: "type",
      text: "Details",
      formatter: (cell,row) => <Button variant="primary" size="sm" onClick={() => handleShowModal("view", row)}>
      View
    </Button>,
    },
    { dataField: "createdAt", text: "Added on", formatter:formatCreatedAt  },
    { dataField: "uniqueID", text: "Beekeeper Count",formatter:formatBeekeeperCount },
    {
      dataField: "actions",
      text: "Edit",
      formatter: (_, row) => (
        <Button variant="primary" size="sm" onClick={() => handleShowModal("edit", row)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="page-container leads-page">
     { !showModal ?
      <LoadingOverlay active={loading} spinner={<BounceLoader />}>
        <ToolkitProvider keyField="userCount" data={financialProduct} columns={columns} search>
          {(props) => (
            <>
              <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0 20px",
          }}
        >
          <div className="d-flex" style={{ width: "80%", gap: 15,alignItems:'center' }}>
            <h3
              style={{
                color: "#ef5a20",
                textShadow: "1px 1px 1px #aaa",
                whiteSpace:'nowrap'
              }}
            >
              Total Financial Product: {financialProduct?.length || 0}
            </h3>
            {/* Custom Search Input */}
            
          </div>

          <Styles.ModalButton onClick={() => handleShowModal("create")}>
            Create Financial Product
          </Styles.ModalButton>
        </div>
 
              <BootstrapTable {...props.baseProps} pagination={paginationFactory(paginationOptions)} hover bootstrap4 />
            </>
          )}
        </ToolkitProvider>
      </LoadingOverlay>
      :
      <AddFinancialProductForm
      mode={type}
      initialData={modalData}
      handleCloseModal={handleCloseModal}
      />
}
    </div>
  );
};

