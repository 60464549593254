import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const currentToken = cookies.get('access_token')

const initialState = {
  singleHoney: {},
  loading: true,
  error: '',
  message: '',
}

export const editSingleHoney = createAsyncThunk(
  'editSingleHoney',
  async ({ _id, formData }) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/honeys/${_id}`,
        formData,
        {
          headers: {
            Accept: '/',
            'Content-Type': 'application/json',
          },
        }
      )
      console.log(
        'id from editHoneySlice ==========================================? ',
        _id
      )
      console.log('formData from editHoneySlice ', formData)
      console.log('res.data from editHoneySlice  ', res.data)
      return res.data
    } catch (error) {
      console.log(error)
      //@ts-ignore
      return error.response.data
    }
  }
)

const editSingleHoneySlice = createSlice({
  name: 'editSingleHoney',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editSingleHoney.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [editSingleHoney.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleHoney, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.message = message
      }
    },
    //@ts-ignore
    [editSingleHoney.rejected]: (state, { message, error }) => {
      state.error = error
      state.message = message
    },
  },
})

export default editSingleHoneySlice.reducer
