import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import close from "../assets/images/close.png";

const readCookie = require("../../cookie.js").readCookie;
const currentToken = readCookie("access_token");

const fundingSourceMapping = {
  GR: ["minGrant", "maxGrant"],
  LN: ["minLoan", "maxLoan"],
  SI: ["minFarmerSelfInvestment", "maxFarmerSelfInvestment"],
  RF: ["minRevolvingFund", "maxRevolvingFund"],
};
const breakdownMapping = {
  GR: ["grant"],
  LN: ["loan"],
  SI: ["farmerSelfInvestment"],
  RF: ["revolvingFund"],
};

export const AddFinancialProductForm = ({
  mode = "create",
  initialData = {},
  handleCloseModal,
}) => {
  const [formData, setFormData] = useState({
    ticketSize: "fixed",
    investmentRequired: "",
    minInvestment: "",
    maxInvestment: "",
    fundingSource: [],
    breakdown: {
      grant: "",
      loan: "",
      farmerSelfInvestment: "",
      revolvingFund: "",
    },
    breakdownPercentage: {
      minGrant: "",
      maxGrant: "",
      minLoan: "",
      maxLoan: "",
      minFarmerSelfInvestment: "",
      maxFarmerSelfInvestment: "",
      minRevolvingFund: "",
      maxRevolvingFund: "",
    },
    loanTerms: {
      tenureMonths: "",
      processingFeeValue: "",
      annualInterestRate: "",
      moratoriumMonths: "",
      repaymentFrequencyMonths: "",
    },
    revolvingFundTerms: {
      annualInterestRate: "",
      repaymentStartMonths: "",
    },
    isEntitySpecific: "no",
    entity: [],
  });
  const [entity, setentity] = useState([]);
 
  const validateFormData = (data) => {
    let errors = {};

    // Helper function to check if a field is empty
    const isEmpty = (value) =>
      value === "" || value === null || value === undefined;

    // Check funding source
    if (!data.fundingSource.length) {
      toast.error("At least one funding source is required");
      return true;
      errors.fundingSource = "At least one funding source is required";
    }

    // Check top-level fields
    if (data.ticketSize === "varying") {
      if (isEmpty(data.minInvestment))
        errors.minInvestment = "Minimum investment is required";
      if (isEmpty(data.maxInvestment))
        errors.maxInvestment = "Maximum investment is required";
      Object.keys(fundingSourceMapping).forEach((source) => {
        if (formData.fundingSource.includes(source)) {
          fundingSourceMapping[source].forEach((field) => {
            if (!formData.breakdownPercentage[field]|| formData.breakdownPercentage[field]<0 || formData.breakdownPercentage[field]>100 ) {
              errors[field] =
                `${field} is required when ${source} is selected.`;
            }
          });
        }
      });
    } else {
      if (isEmpty(data.investmentRequired))
        errors.investmentRequired = "Investment required is mandatory";
      // Check breakdown fields
      Object.keys(breakdownMapping).forEach((source) => {
        if (formData.fundingSource.includes(source)) {
          breakdownMapping[source].forEach((field) => {
            if (!formData.breakdown[field]) {
              errors[field] =
                `${field} is required when ${source} is selected.`;
            }
          });
        }
      });
    }

    if (data.fundingSource?.includes("LN")) {
      Object.entries(data.loanTerms).forEach(([key, value]) => {
        if (isEmpty(value)) errors[`loanTerms.${key}`] = `${key} is required`;
      });
      // If isEntitySpecific is "yes", ensure at least one entity is selected
      if (data.isEntitySpecific === "yes" && !data.entity.length) {
        errors["entity"] = "At least one partner is required";
      }
    }

    if (data.fundingSource?.includes("RF")) {
      // Check revolving fund terms
      Object.entries(data.revolvingFundTerms).forEach(([key, value]) => {
        if (isEmpty(value))
          errors[`revolvingFundTerms.${key}`] = `${key} is required`;
      });
    }
    console.log(Object,Object.keys(errors).length );
    return Object.keys(errors).length ? true : false;
  };

  const refineFormData = (formData) => {
    const enabledSources = formData.fundingSource; // Get selected funding sources
    const refinedBody = {
      ...formData,
      fundingSource: swapValues(formData.fundingSource),
      isEntitySpecific: formData.isEntitySpecific === "yes",
      entity: formData.entity.map(it => it.value),
    };
  
    // Clear breakdown fields that are not enabled
    refinedBody.breakdown = Object.keys(formData.breakdown).reduce((acc, key) => {
      acc[key] = enabledSources.some(source => breakdownMapping[source]?.includes(key))
        ? formData.breakdown[key] // Keep value if enabled
        : ""; // Clear value if not enabled
      return acc;
    }, {});
  
    // Clear breakdownPercentage fields that are not enabled
    refinedBody.breakdownPercentage = Object.keys(formData.breakdownPercentage).reduce((acc, key) => {
      acc[key] = enabledSources.some(source => fundingSourceMapping[source]?.includes(key))
        ? formData.breakdownPercentage[key] // Keep value if enabled
        : ""; // Clear value if not enabled
      return acc;
    }, {});
  
    // Clear loanTerms if LN (Loan) is not selected
    if (!enabledSources.includes("LN")) {
      Object.keys(refinedBody.loanTerms).forEach(key => refinedBody.loanTerms[key] = ""); 
      refinedBody.isEntitySpecific = false;
      refinedBody.entity = [];
    }
  
    // Clear revolvingFundTerms if RF (Revolving Fund) is not selected
    if (!enabledSources.includes("RF")) {
      Object.keys(refinedBody.revolvingFundTerms).forEach(key => refinedBody.revolvingFundTerms[key] = ""); 
    }
  
    // Clear min/max investment fields if ticketSize is "fixed"
    if (formData.ticketSize !== "varying") {
      refinedBody.minInvestment = "";
      refinedBody.maxInvestment = "";
    } else {
      refinedBody.investmentRequired = ""; // Clear fixed investment if varying is selected
    }
  
    console.log("Refined Body:", refinedBody);
    return refinedBody;
  };
  
  useEffect(() => {
    if(mode!=='create' && initialData && entity.length>0){
      setFormData(prevNp => {
        const updatedEntity = prevNp.entity.map(obj1 => {
          // console.log("Current entity item:", obj1);
      
          const match = entity?.find(obj2 => obj2._id === obj1);
          // console.log("Match found:", match);
      
          return match ? {
            value:match._id,
            label: match.name,
            } : {
              value:obj1|| '',
              label: obj1|| '',
              }
        });          
        return {
          ...prevNp,
          entity: updatedEntity
        };
      });
  
    }
  }, [entity,mode,initialData])
  
  
  useEffect(() => {
    fetchEntity();
    if (mode !== "create" && initialData) {
      console.log(initialData);
      const reverseSwapValues = (inputArray) => {
        console.log(inputArray);
        const mapping = {
          grant: "GR",
          loan: "LN",
          farmer_self_investment: "SI",
          revolving_fund: "RF"
        };
      
        return inputArray?.map(item => mapping[item] || item);
      };
      setFormData({
        ticketSize: initialData.ticketSize ?? "fixed",
        investmentRequired: initialData.investmentRequired ?? "",
        minInvestment: initialData.minInvestment ?? "",
        maxInvestment: initialData.maxInvestment ?? "",
        fundingSource: reverseSwapValues(initialData.fundingSource) ?? [],
        breakdown: {
          grant: initialData.breakdown?.grant ?? "",
          loan: initialData.breakdown?.loan ?? "",
          farmerSelfInvestment:
            initialData.breakdown?.farmerSelfInvestment ?? "",
          revolvingFund: initialData.breakdown?.revolvingFund ?? "",
        },
        breakdownPercentage: {
          minGrant: initialData.breakdownPercentage?.minGrant ?? "",
          maxGrant: initialData.breakdownPercentage?.maxGrant ?? "",
          minLoan: initialData.breakdownPercentage?.minLoan ?? "",
          maxLoan: initialData.breakdownPercentage?.maxLoan ?? "",
          minFarmerSelfInvestment:
            initialData.breakdownPercentage?.minFarmerSelfInvestment ?? "",
          maxFarmerSelfInvestment:
            initialData.breakdownPercentage?.maxFarmerSelfInvestment ?? "",
          minRevolvingFund:
            initialData.breakdownPercentage?.minRevolvingFund ?? "",
          maxRevolvingFund:
            initialData.breakdownPercentage?.maxRevolvingFund ?? "",
        },
        loanTerms: {
          tenureMonths: initialData.loanTerms?.tenureMonths ?? "",
          processingFeeValue: initialData.loanTerms?.processingFeeValue ?? "",
          annualInterestRate: initialData.loanTerms?.annualInterestRate ?? "",
          moratoriumMonths: initialData.loanTerms?.moratoriumMonths ?? "",
          repaymentFrequencyMonths:
            initialData.loanTerms?.repaymentFrequencyMonths ?? "",
        },
        revolvingFundTerms: {
          annualInterestRate:
            initialData.revolvingFundTerms?.annualInterestRate ?? "",
          repaymentStartMonths:
            initialData.revolvingFundTerms?.repaymentStartMonths ?? "",
        },
        isEntitySpecific: (initialData.isEntitySpecific?'yes':'no') ?? "no",
        entity: initialData.entity ?? [],
      });
    }
  }, [mode, initialData]);
  const swapValues = (inputArray) => {
    const mapping = {
      GR: "grant",
      LN: "loan",
      SI: "farmer_self_investment",
      RF: "revolving_fund"
    };
  
    return inputArray.map(item => mapping[item] || item); // Swap values if found, otherwise keep original
  };
  
  const handleChange = (e) => {
    if (!/^\d*$/.test(e.target.value)){
      toast.error("Please Enter a Valid Number");
      return 
    }
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode == "view") {
      handleCloseModal(formData);
      return;
    }
    if (validateFormData(formData)) {
      toast.error("Please fill all the required fields correctly");
      return;
    }
    
    const body=refineFormData(formData)
    console.log(body,'refined body');
    try{
      const url = mode=='edit'
    ? `${process.env.REACT_APP_API_URL}/api/v1/humblebee/financial-product/${initialData._id}`
    : `${process.env.REACT_APP_API_URL}/api/v1/humblebee/financial-product/`;
    console.log(url,'url od submit');
     fetch(url, {
       method: mode=='edit'?'PUT':'POST',
       headers: {
         'Authorization': `Beared ${JSON.parse(readCookie('access_token'))}`,
          "Content-Type": "application/json"
       },
       body: JSON.stringify(body),
 
     })
       .then(data => data.json())
       .then(data => {
        if(data.error){
          toast.error('Error came While submitting Form')
        }
        else{
          toast.success(mode=='edit'? 'Financial Product Updated Successfully' : 'Financial Product submitted Successfully')
          handleCloseModal();
        }
         console.log(data);
       })
   } catch (error) {
    console.log(error);
    toast.error('Error came While submitting Form')
   }
  };

  const fetchEntity = () => {
    try {
      fetch(process.env.REACT_APP_API_URL + "/api/v1/humblebee/entity", {
        method: "GET",
        headers: {
          Authorization: `Beared ${JSON.parse(readCookie("access_token"))}`,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);

          setentity(data);
        });
    } catch (error) {
      console.log(error, "err");
    }
  };

  const handleFundingSourceChange = (source) => {
    if(isViewMode){
      return; // Do nothing if view mode
    }
    const isChecked = formData.fundingSource.includes(source);
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        fundingSource: prev.fundingSource.filter((s) => s !== source),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        fundingSource: [...prev.fundingSource, source],
      }));
    }
  };

  const isViewMode = mode === "view";

  return (
    <form onSubmit={handleSubmit}>
      <div style={{
        display:'flex',
        flexDirection:'row',
        marginBottom:30
      }}>
      <button
            onClick={handleCloseModal}
            style={{
              background: "none",
              border: "none",
              marginRight:20,
            }}
          >
            <img
              style={{
                width: 15,
                zIndex: 10,
                background: "white",
              }}
              src={close}
              alt="Close"
            />
          </button>
      <h2
        style={{
          color: "#ef5a20",
          textShadow: "1px 1px 1px #aaa",
          marginBottom: 0,

        }}
      >
        {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Create"}{" "}
       
        Financial Product
      </h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          margin: "10px 0px",
          width: 250,
        }}
      >
        <label
          style={{
            whiteSpace: "nowrap",
            fontWeight: "600",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Ticket Size:
        </label>

       {isViewMode? <p style={{
        border:'0.5px solid #CBCBCB',
        borderRadius:8,
        padding:'5px 10px',
        marginBottom:0
       }}>{formData.ticketSize}</p>
       :
        <select
          className="custom-select"
          value={formData.ticketSize}
          onChange={(e) => {
            const selectedValue = e.target.value;
            console.log(selectedValue);

            setFormData((prev) => ({
              ...prev,
              ticketSize: selectedValue,
              ...(selectedValue === "fixed"
                ? { minInvestment: undefined, maxInvestment: undefined }
                : { investment: undefined }), // Reset unnecessary fields
            }));
          }}
        >
          <option value="fixed">Fixed</option>
          <option value="varying">Varying</option>
        </select>
}
      </div>

      {formData.ticketSize == "fixed" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            margin: "10px 0px",
          }}
        >
          <label
            style={{ whiteSpace: "nowrap", fontWeight: "600", marginBottom: 0 }}
          >
            Investment Required (INR):
          </label>
          <input
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 8,
              padding: "1px 8px",
            }}
            name="investmentRequired"
            value={formData.investmentRequired}
            onChange={handleChange}
            disabled={isViewMode}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            margin: "10px 0px",
          }}
        >
          <label
            style={{ whiteSpace: "nowrap", fontWeight: "600", marginBottom: 0 }}
          >
            (Min) Investment required (INR):
          </label>
          <input
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 8,
              padding: "1px 8px",
            }}
            name="minInvestment"
            value={formData.minInvestment}
            onChange={handleChange}
            disabled={isViewMode}
          />
          <label
            style={{ whiteSpace: "nowrap", fontWeight: "600", marginBottom: 0 }}
          >
            (Max) Investment required (INR):
          </label>
          <input
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 8,
              padding: "1px 8px",
            }}
            name="maxInvestment"
            value={formData.maxInvestment}
            onChange={handleChange}
            disabled={isViewMode}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          margin: "10px 0px",
        }}
      >
        <label
          style={{ whiteSpace: "nowrap", fontWeight: "600", marginBottom: 0 }}
        >
          Funding Source:
        </label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 20,
            flexWrap: "wrap",
          }}
        >
          <label>
            <input
              style={{ marginRight: 5 }}
              type="checkbox"
              value="GR"
              checked={formData.fundingSource.includes("GR")}
              onChange={() => handleFundingSourceChange("GR")}
            />{" "}
            Grant (GR)
          </label>
          <label>
            <input
              style={{ marginRight: 5 }}
              type="checkbox"
              value="LN"
              checked={formData.fundingSource.includes("LN")}
              onChange={() => handleFundingSourceChange("LN")}
            />{" "}
            Loan (LN)
          </label>
          <label>
            <input
              style={{ marginRight: 5 }}
              type="checkbox"
              value="SI"
              checked={formData.fundingSource.includes("SI")}
              onChange={() => handleFundingSourceChange("SI")}
            />{" "}
            Farmer Self-Investment (SI)
          </label>
          <label>
            <input
              style={{ marginRight: 5 }}
              type="checkbox"
              value="RF"
              checked={formData.fundingSource.includes("RF")}
              onChange={() => handleFundingSourceChange("RF")}
            />{" "}
            Revolving Fund (RF)
          </label>
        </div>
      </div>

      {formData.ticketSize === "fixed" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            margin: "10px 0px",
          }}
        >
          <p
            style={{
              whiteSpace: "nowrap",
              marginBottom: 10,
              fontWeight: "600",
            }}
          >
            Breakdown of Financial Contributions:
          </p>
          {formData.fundingSource.includes("GR") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Grant (GR):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                name="grant"
                value={formData.breakdown.grant}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdown: { ...prev.breakdown, grant: value },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("LN") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Loan (LN):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                name="loan"
                value={formData.breakdown.loan}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdown: { ...prev.breakdown, loan: value },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("SI") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Farmer Self-Investment (SI):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                name="farmerSelfInvestment"
                value={formData.breakdown.farmerSelfInvestment}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdown: {
                      ...prev.breakdown,
                      farmerSelfInvestment: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("RF") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Revolving Fund (RF):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                name="revolvingFund"
                value={formData.breakdown.revolvingFund}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdown: {
                      ...prev.breakdown,
                      revolvingFund: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            margin: "10px 0px",
          }}
        >
          <p
            style={{
              whiteSpace: "nowrap",
              marginBottom: 10,
              fontWeight: "600",
            }}
          >
            Breakdown of Financial Contributions:
          </p>
          {formData.fundingSource.includes("GR") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Min Grant (%):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                value={formData.breakdownPercentage.minGrant}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      minGrant: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
              Max Grant (%)
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                value={formData.breakdownPercentage.maxGrant}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      maxGrant: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("LN") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Min Loan (%):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                value={formData.breakdownPercentage.minLoan}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      minLoan: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
              Max Loan (%)
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                value={formData.breakdownPercentage.maxLoan}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      maxLoan: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("SI") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Min Farmer Self-Investment (%):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                value={formData.breakdownPercentage.minFarmerSelfInvestment}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      minFarmerSelfInvestment: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
              Max Farmer Self-Investment (%)
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                value={formData.breakdownPercentage.maxFarmerSelfInvestment}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      maxFarmerSelfInvestment: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
          {formData.fundingSource.includes("RF") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "black",
                  marginBottom: 0,
                  marginRight: 10,
                }}
              />
              Min Revolving Fund (%):
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                  marginRight: 10,
                }}
                value={formData.breakdownPercentage.minRevolvingFund}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      minRevolvingFund: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
              Max Revolving Fund (%)
              <input
                style={{
                  border: "1px solid #CBCBCB",
                  borderRadius: 8,
                  padding: "1px 8px",
                  marginLeft: 10,
                }}
                value={formData.breakdownPercentage.maxRevolvingFund}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^\d*$/.test(value)){
                    toast.error("Please Enter a Valid Number");
                    return 
                  }
                  setFormData((prev) => ({
                    ...prev,
                    breakdownPercentage: {
                      ...prev.breakdownPercentage,
                      maxRevolvingFund: value,
                    },
                  }));
                }}
                disabled={isViewMode}
              />
            </div>
          )}
        </div>
      )}

      {formData.fundingSource.includes("LN") && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            margin: "10px 0px",
          }}
        >
          <p
            style={{
              whiteSpace: "nowrap",
              marginBottom: 10,
              fontWeight: "600",
              marginTop: 20,
            }}
          >
            Loan & Repayment Terms:
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Loan tenureMonths (months)
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
              }}
              value={formData.loanTerms.tenureMonths}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  loanTerms: {
                    ...prev.loanTerms,
                    tenureMonths: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Processing Fee {formData.ticketSize == "fixed" ? "in INR" : "in %"}{" "}
            :
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
                marginRight: 10,
              }}
              value={formData.loanTerms.processingFeeValue}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  loanTerms: {
                    ...prev.loanTerms,
                    processingFeeValue: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Annual Interest rate(%) :
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
              }}
              value={formData.loanTerms.annualInterestRate}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  loanTerms: {
                    ...prev.loanTerms,
                    annualInterestRate: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Moratorium (months):
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
              }}
              value={formData.loanTerms.moratoriumMonths}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  loanTerms: {
                    ...prev.loanTerms,
                    moratoriumMonths: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Repayment frequency (Once in every
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
                width: 50,
                marginRight: 10,
              }}
              value={formData.loanTerms.repaymentFrequencyMonths}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  loanTerms: {
                    ...prev.loanTerms,
                    repaymentFrequencyMonths: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />{" "}
            months):
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              margin: "10px 0px",
              width: 500,
            }}
          >
            <label
              style={{
                whiteSpace: "nowrap",
                fontWeight: "600",
                marginBottom: 0,
              }}
            >
              Is this loan product specific to a partner:
            </label>
           {isViewMode ?
           <p style={{
            border:'0.5px solid #CBCBCB',
            borderRadius:8,
            padding:'5px 10px',
            marginBottom:0
           }}>{formData.isEntitySpecific}</p>
           :
            <select
              className="custom-select"
              value={formData.isEntitySpecific}
              onChange={(e) => {
                const selectedValue = e.target.value;
                console.log(selectedValue);

                setFormData((prev) => ({
                  ...prev,
                  isEntitySpecific: selectedValue,
                }));
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>}
          </div>

          {formData.isEntitySpecific == "yes" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                margin: "10px 0px",
                width: 500,
              }}
            >
              <label
                style={{
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                  marginBottom: 0,
                }}
              >
                Select the partner from the dropdown:
              </label>
             {isViewMode?
             <p style={{
              border:'0.5px solid #CBCBCB',
              borderRadius:8,
              padding:'5px 10px',
              marginBottom:0
             }}>{formData.entity.map(it=>(it.label)).join(', ')}</p>
             :
            
                <MultiSelect
                className="custom-multipleSelect"
                options=
                  {entity?.map((item) => (
                    {value: item._id,
                    label: item.name,}
                     ))
                }
                
                value={formData.entity}
                onChange={w=>{
                  setFormData((prev) => ({
                    ...prev,
                    entity: w,
                  }));
                }}
                labelledBy="Select Entity Types"
                hasSelectAll={false}
              />
              }
            </div>
          )}
        </div>
      )}

      {formData.fundingSource.includes("RF") && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            margin: "10px 0px",
          }}
        >
          <p
            style={{
              whiteSpace: "nowrap",
              marginBottom: 10,
              fontWeight: "600",
              marginTop: 20,
            }}
          >
            Revolving Fund terms:
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Annual Interest Rate (%):
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
              }}
              value={formData.revolvingFundTerms.annualInterestRate}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  revolvingFundTerms: {
                    ...prev.revolvingFundTerms,
                    annualInterestRate: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                marginBottom: 0,
                marginRight: 10,
              }}
            />
            Repayment starts after
            <input
              style={{
                border: "1px solid #CBCBCB",
                borderRadius: 8,
                padding: "1px 8px",
                marginLeft: 10,
                marginRight: 10,
              }}
              value={formData.revolvingFundTerms.repaymentStartMonths}
              onChange={(e) => {
                const value = e.target.value;
                if (!/^\d*$/.test(value)){
                  toast.error("Please Enter a Valid Number");
                  return 
                }
                setFormData((prev) => ({
                  ...prev,
                  revolvingFundTerms: {
                    ...prev.revolvingFundTerms,
                    repaymentStartMonths: value,
                  },
                }));
              }}
              disabled={isViewMode}
            />{" "}
            months
          </div>
        </div>
      )}

      <div
        style={{
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            padding: "10px 40px",
            backgroundColor: "#ef5a20",
            color: "white",
            borderRadius: 8,
            cursor: "pointer",
            border: "1px solid #CBCBCB",
            fontSize: 16,
            fontWeight: "600",
          }}
          type="submit"
        >
          {" "}
          {mode == "create" ? "Submit" : mode == "edit" ? "Update" : "Close"}
        </button>
      </div>
    </form>
  );
};
