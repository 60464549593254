import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal, Form } from "react-bootstrap";
import * as Styles from "../Users/style";
import LoadingOverlay from "react-loading-overlay";
import { BounceLoader } from "react-spinners";
import { MultiSelect } from "react-multi-select-component";
import close from "../assets/images/close.png";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Select from 'react-select'
import moment from 'moment'
import { toast } from 'react-toastify'

const entityTypes = [
  { value: "Project Funder", label: "Project Funder" },
  { value: "Asset Grant Funder", label: "Asset Grant Funder" },
  { value: "Credit Provider", label: "Credit Provider" },
  { value: "Subsidy Provider", label: "Subsidy Provider" },
  { value: "Revolving Fund Provider", label: "Revolving Fund Provider" },
];
const readCookie = require('../../cookie.js').readCookie
const currentToken = readCookie('access_token')
export const Entity = () => {
  const [originalEntitys, setOriginalEntitys] = useState([]); // Stores the unfiltered data
  const [Entitys, setEntitys] = useState([]); // Stores the filtered data
  const [searchQuery, setSearchQuery] = useState(""); // Stores the search input value
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    type: "",
    name: "",
  });
  const [selectedType, setSelectedType] = useState(null);
  const [isEdit, setisEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of records per page
  const [totalRecords, setTotalRecords] = useState(0); // Total records from API

  useEffect(() => {
    fetchProjects(currentPage, pageSize);
  }, [currentPage, pageSize]); // Refetch when page or limit changes
  

 
  const fetchProjects = (page = 1, limit = 10) => {
    setLoading(true);
    
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/humblebee/entity?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${JSON.parse(readCookie('access_token'))}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("Fetched Data:", data);
  
        setOriginalEntitys(data);
        setEntitys(data);
        setTotalRecords(data.length || 0); // Set total count for pagination
        setLoading(false);
      })
      .catch(error => {
        console.error(error, 'Error fetching projects');
        setLoading(false);
      });
  };
  
  // Handle search
  useEffect(() => {
    let filteredData = originalEntitys;
    
    if (searchQuery) {
      filteredData = filteredData.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    
    if (selectedType) {
      filteredData = filteredData.filter((item) => item.type.includes(selectedType.value));
    }
    
    setEntitys(filteredData);
  }, [searchQuery, selectedType, originalEntitys]);

  const paginationOptions = {
    totalSize: totalRecords, // Set total records count
    sizePerPage: pageSize,
    sizePerPageList: [10, 25, 50], // Page size options
    page: currentPage,
    onPageChange: (page) => setCurrentPage(page), // Change page
    onSizePerPageChange: (size) => {
      setPageSize(size);
      setCurrentPage(1); // Reset to first page when changing page size
    }
  };

  
  const handleShowModal = (type, Entity = null) => {
    if (Entity) {
      setModalData({
        type: Entity.type?.map(item=>({
          label: item,
          value: item,
        })),
        name: Entity.name,
      });
      setisEdit(Entity)
    } else {
      setModalData({
        type: [],
        name: "",
      });
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setisEdit(false)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTypeChange = (event) => {
    setModalData((prev) => ({
      ...prev,
      type: event,
    }));
  };

  const handleSubmit = () => {
    if(modalData.name==''||modalData.type.length==0 ){
      toast.error('Please fill all the required fields')
      return;
    }
    const body={
      name:modalData.name,
      type:modalData.type.map((item) => item.value),
    }
    console.log("Submitted body:", body);

   try {
    const url = isEdit?._id
    ? `${process.env.REACT_APP_API_URL}/api/v1/humblebee/entity/${isEdit._id}`
    : `${process.env.REACT_APP_API_URL}/api/v1/humblebee/entity`;
    console.log(url,isEdit);
     fetch(url, {
       method: isEdit?._id?'PUT':'POST',
       headers: {
         'Authorization': `Beared ${JSON.parse(readCookie('access_token'))}`,
          "Content-Type": "application/json"
       },
       body: JSON.stringify(body),
 
     })
       .then(data => data.json())
       .then(data => {
        if(data.error){
          toast.error('Error came While submitting Form')
        }
        else{
          toast.success(isEdit?._id? 'Form Updated Successfully' : 'Form submitted Successfully')
          fetchProjects()
          handleCloseModal();
          setisEdit(false)
        }
         console.log(data);
       })
   } catch (error) {
    console.log(error);
    toast.error('Error came While submitting Form')
   }

  };
  const formatCreatedAt = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return <span>-</span>
  }
  const formatUserCount = (cell, row) => {
    if (cell) {
      return 'ascsaca s'
    } else return <span>-</span>
  }
  const columns = [
    { dataField: "name", text: "Entity Name" },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell) => <p>{cell.join(",")}</p>,
    },
    { dataField: "createdAt", text: "Added on", formatter:formatCreatedAt },
    { dataField: "userCount", text: "User Count",  formatter:formatUserCount},
    {
      dataField: "actions",
      text: "Edit",
      formatter: (_, row) => (
        <Button variant="primary" size="sm" onClick={() => handleShowModal("edit", row)}>
          Edit
        </Button>
      ),
    },
  ];
  
  return (
    <div className="page-container leads-page">
      <LoadingOverlay active={loading} spinner={<BounceLoader />}>
        <ToolkitProvider keyField="userCount" data={Entitys} columns={columns} search>
          {(props) => (
            <>
              <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0 20px",
          }}
        >
          <div className="d-flex" style={{ width: "80%", gap: 15,alignItems:'center' }}>
            <h3
              style={{
                color: "#ef5a20",
                textShadow: "1px 1px 1px #aaa",
                whiteSpace:'nowrap'
              }}
            >
              Total Entity: {Entitys?.length || 0}
            </h3>
            {/* Custom Search Input */}
            <Styles.SearchInput
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by Entity Name"
          />
          <Select
            options={entityTypes}
            value={selectedType}
            onChange={setSelectedType}
            placeholder="Filter by role"
            isClearable
            styles={{
              container: provided => ({
                ...provided,
                marginLeft: '10px',
                width: '300px',
              }),
            }}
          />
          </div>

          <Styles.ModalButton onClick={() => handleShowModal("create")}>
            Create Entity
          </Styles.ModalButton>
        </div>
 
              <BootstrapTable {...props.baseProps} pagination={paginationFactory(paginationOptions)} hover bootstrap4 />
            </>
          )}
        </ToolkitProvider>
      </LoadingOverlay>

      {/* Modal for Creating/Editing */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit?._id ? "Edit Entity" : "Create Entity"}
          </Modal.Title>
          <button
            onClick={handleCloseModal}
            style={{
              background: "white",
              border: "none",
              position: "absolute",
              right: 10,
              zIndex:2
            }}
          >
            <img
              style={{
                width: 15,
                zIndex: 10,
                background: "white",
              }}
              src={close}
              alt="Close"
            />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="d-flex align-items-center" style={{ gap: 20, margin: "10px 0px" }}>
              <Form.Label className="text-nowrap">Entity Name:</Form.Label>
              <Form.Control type="text" name="name" value={modalData.name} onChange={handleChange} placeholder="Enter Entity name" />
            </Form.Group>
            <div
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width:'100%'
              }}
            >
              <label>Select Entity Type: </label>
              <MultiSelect
                className="custom-multipleSelect2"
                options={entityTypes}
                value={modalData.type}
                onChange={handleTypeChange}
                labelledBy="Select Entity Types"
                hasSelectAll={false}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit?._id ? "Update Entity" : "Create Entity"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
