// MapComponent.js
import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, MarkerClustererF } from "@react-google-maps/api";
import { ToggleButton } from "react-bootstrap";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";

const containerStyle = {
    width: 'auto',
    height: '700px',
  }

const indiaBounds = {
north: 37.0902,  // Top-most part of India
south: 6.554607, // Bottom-most part of India
west: 68.111378, // Left-most (Gujarat)
east: 97.395358, // Right-most (Arunachal Pradesh)
};

const generateColor = (index) => {
    const colors = [
      "red", "blue", "green", "yellow", "purple", "orange", "pink", "cyan", "lime", "brown"
    ];
    return colors[index % colors.length]; // Loop through colors if more projects exist
  };
  
// Function to generate Google Maps marker icon URL with a specific color
const getMarkerIcon = (color) => `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`;

const markerIcons = {
red: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // No project_id
green: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Has project_id
};

const MapComponent = ({clusterUserCoordinates}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBg4tz2fOqTqAny-Hph8blHRP9YeRTuIDg",
  });
  console.log("Loading map...", isLoaded);


  const [projectColors, setProjectColors] = useState({});

  const [selectedView, setSelectedView] = useState("cluster");

  // Assign unique colors to project IDs dynamically
  useEffect(() => {
    const uniqueProjects = [...new Set(clusterUserCoordinates.map((data) => data.project_id))];
    const newProjectColors = {};
    uniqueProjects.forEach((projectId, index) => {
      newProjectColors[projectId] = getMarkerIcon(generateColor(index));
    });
    setProjectColors(newProjectColors);

    //print length of clusterUserCoordinates
    console.log("Length of clusterUserCoordinates: ", clusterUserCoordinates.length);

  }, [clusterUserCoordinates]);


  if (!isLoaded) return <p>Loading...</p>;

  const openUserProfile = (userId) => {
    //opena new tab with user profile
    console.log("Open user profile with user ID: ", userId);
    window.open(`/user-profile/${userId}`, "_blank");
    }


  return (
    <>
    <div style={{display: 'flex', justifyContent: 'right', alignItems: 'right', gap: 1, margin: 1}}>
    <FormGroup>
    <FormControlLabel control={<Switch  id="toggle-check"
        checked={selectedView === "cluster"}
        onChange={(e) => setSelectedView(e.target.checked ? "cluster" : "non-cluster")}
        placeholder="Cluster" />} label={selectedView === "cluster" ? "Cluster View" : "Beekeepers View"} />
    </FormGroup>
    </div>

        

    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: 18.52043, lng: 73.856743 }}
      zoom={5}
      options={{
        restriction: { latLngBounds: indiaBounds, strictBounds: true },
        mapTypeId: "terrain",
        mapTypeControl: false,
      }}
    >
      {selectedView !== "cluster" ? (
        // Non-clustered markers
        clusterUserCoordinates.map((data, index) => {
          const iconUrl = data?.projectId ? markerIcons.green : markerIcons.red;

          return (
            <Marker
              key={index}
              position={{ lat: data.lat, lng: data.lng }}
              onClick={() => openUserProfile(data.userId)}
              icon={{
                url: iconUrl,
                scaledSize: isLoaded
                  ? new window.google.maps.Size(32, 32)
                  : undefined,
              }}
            />
          );
        })
      ) : (
        // Clustered markers
        <MarkerClustererF>
          {(clusterer) =>
            clusterUserCoordinates.map((data, index) => {
              const iconUrl = data?.projectId ? markerIcons.green : markerIcons.red;

              return (
                <Marker
                  key={index}
                  position={{ lat: data.lat, lng: data.lng }}
                  onClick={() => openUserProfile(data.userId)}
                  clusterer={clusterer}
                  icon={{
                    url: iconUrl,
                    scaledSize: isLoaded
                      ? new window.google.maps.Size(32, 32)
                      : undefined,
                  }}
                />
              );
            })
          }
        </MarkerClustererF>
      )}
    </GoogleMap>
    </>
  );
};

export default MapComponent;
