import React, { useState, useRef, useMemo, useEffect } from 'react'
import * as Styles from './style'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import close from '../../../assets/images/close.png'
import { InputGroup } from '../../../shared/InputGroup/InputGroup'
import { TextareaGroup } from '../../../shared/TextareaGroup/TextareaGroup'
import { makeFarmer } from '../../../reduxLogic/reducers/farmers/makeFarmerSlice'
import { getAllFarmers } from '../../../reduxLogic/reducers/farmers/getAllFarmersSlice'
import { MultiSelect } from 'react-multi-select-component'
import { makeSupplier } from '../../../reduxLogic/reducers/suppliers/makeSupplierSlice'
import { getAllSuppliers } from '../../../reduxLogic/reducers/suppliers/getAllSupplierSlice'
import { BlockData } from '../../../shared/Constants/blocks'
import { SelectGroup } from '../../../shared/SelectGroup/SelectGroup'
import { HONEY_FLORAL_TYPES } from '../../../shared/Constants/Constants'
import { makeHoney } from '../../../reduxLogic/reducers/honeys/makeHoneySlice'
import { getAllHoney } from '../../../reduxLogic/reducers/honeys/getAllHoneySlice'

const initialValues = {
  floralSource: '',
  honeyType: '',
  region: '',
  color: '',
  taste: '',
  currentToken: '',
}

const honeySchema = Yup.object().shape({
  floralSource: Yup.string().required('Floral Source is required'),
  honeyType: Yup.string().required('Honey TYpe is requireed'),
  region: Yup.string().required('Region is required'),
})

const HoneyForm = props => {
  const { openModal, setOpenModal } = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedHoneyType, setSelectedHoneyType] = useState('')

  //   const { honeyData } = useSelector((state) => state.allHoney);
  //console.log("this is allSuppliers from  SupplierForm ==> ", suppliersData);

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenModal(prev => !prev)
  }

  const handleHoneyStateChange = value => {
    setSelectedHoneyType(value)
  }

  const handleSubmit = (values) => {
    console.log("These are honey values in handleSubmit =>", values);
    setIsSubmitting(true);
  
    const token = localStorage.getItem("humblebeeAuthToken");
  
    // Create a JSON object instead of FormData
    const honeyData = {
      floralSource: values.floralSource,
      honeyType: values.honeyType,
      region: values.region,
      color: values.color,
      taste: values.taste,
      currentToken: token,  // Include token in body
    };
  
    console.log("Sending JSON data:", honeyData);
  
    dispatch(makeHoney(honeyData))
      .then(() => dispatch(getAllHoney()))
      .then(() => setOpenModal(false))
      .then(() => toast.success("Honey Added Successfully"));
  };
  

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={honeySchema}
          validateOnMount
        >
          {formik => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Honey Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainFormDiv>
                <InputGroup
                  PlaceholderText="Enter Floral Source"
                  LabelText="Floral Source"
                  name="floralSource"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />

                <SelectGroup
                  PlaceholderText="Select Honey Type"
                  LabelText="Honey Type"
                  name="honeyType"
                  onChange={e => {
                    handleHoneyStateChange(e.target.value)
                    formik.handleChange(e)
                  }}
                  value={selectedHoneyType}
                  options={HONEY_FLORAL_TYPES}
                  star="*"
                />

                <InputGroup
                  PlaceholderText="Enter Region"
                  LabelText="Region"
                  name="region"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Enter Color"
                  LabelText="Color"
                  name="color"
                  type="text"
                  onChange={formik.handleChange}
                />

                <TextareaGroup
                  PlaceholderText="Enter Taste Profile"
                  LabelText="Taste Profile"
                  name="taste"
                  type="text"
                  onChange={formik.handleChange}
                />
              </Styles.MainFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Styles.CreateButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  )
}

export default HoneyForm
