import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const initialState = {
  status: '',
  honeyData: [],
  loading: false,
  error: '',
}

const currentToken = cookies.get('access_token')

export const getAllHoney = createAsyncThunk('getAllHoney', async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/honeys`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${currentToken}`,
        },
      }
    )
    console.log('this is res.data from getAllHoneySlice => ', res.data)
    return res.data
  } catch (err) {
    console.log('err', err)
    return err.response.data
  }
})

const getAllHoneylice = createSlice({
  name: 'getAllHoney',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllHoney.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllHoney.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.honeyData = payload.honeys
        state.message = payload.message
      }
    },

    [getAllHoney.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllHoneylice.reducer
