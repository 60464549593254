import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const initialState = {
  singleHoney: {},
  loading: true,
  error: '',
  message: '',
}

const currentToken = cookies.get('access_token')

export const makeHoney = createAsyncThunk('makeHoney', async body => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/honeys/`,
      body,
      {
        headers: { 'Content-Type': 'application/json' , 'authorization': `Bearer ${currentToken}` },
      }
    )

    console.log('body from makeHoney:', body)
    console.log('res.data from makeHoney:', res.data)

    return res.data
  } catch (error) {
    console.log('Error in makeHoney:', error)
    return error.response?.data || { message: 'Unknown error occurred' }
  }
})

const makeHoneySlice = createSlice({
  name: 'makeHoney',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [makeHoney.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [makeHoney.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleHoney, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = message
      } else {
        state.singleHoney = singleHoney
        state.message = message
      }
    },
    //@ts-ignore
    [makeHoney.rejected]: (state, { message, error }) => {
      state.error = error
      state.message = message
    },
  },
})

export default makeHoneySlice.reducer
