/** @format */

import React, { useEffect, useMemo, useState } from 'react'
import * as Styles from './style'
import { Link, useParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { changeDateTimeFormat } from '../shared/Helper'
import { usePagination, useTable, useSortBy } from 'react-table'
import editIcon from '../assets/images/edit.png'
import deleteIcon from '../assets/images/delete.png'
import ProjectForm from './components/ProjectForm/ProjectForm'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjects } from '../reduxLogic/reducers/projects/getAllProjectSlice'
import { getAllClusters } from '../reduxLogic/reducers/clusters/getAllClustersSlice'
import { getClusterByProject } from '../reduxLogic/reducers/clusters/getClusterByProjectSlice'
import EditProjectForm from './components/EditProjectForm/EditProjectForm'
import DeleteModal from './components/DeleteModal/DeleteModal'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()


const Clusters = () => {
  var { projectId } = useParams()
  const userData = cookies.get('userData')

  const formatDate = date => {
    if (!date) return '-'
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0')
    const month = String(d.getMonth() + 1).padStart(2, '0') // getMonth() is zero-based
    const year = d.getFullYear()
    return `${day}-${month}-${year}`
  }

  const Project_TABLE_COLUMNS = [
    { Header: 'Cluster ID', accessor: 'clusterId' },
    { Header: 'Cluster', accessor: 'clusterName' },
    { Header: 'Project', accessor: 'projectId.name' },
    { Header: 'Number Of Trainee' },
    {
      Header: 'BeeMitra',
      accessor: 'beeMitras',
      Cell: ({ value }) => {
        if (!value || value.length === 0) {
          return 'No BeeMitra';
        } else {
          return value.map((beeMitra, index) => (
            <span key={index}>
              <a href={`/beemitras/${beeMitra}`}>BeeMitra {index + 1}</a>
              {index < value.length - 1 ? ', ' : ''}
            </span>
          ));
        }
      }
    },    
    { Header: 'Status', accessor: 'status' },
    { Header: 'State', accessor: 'state' },
    { Header: 'District', accessor: 'district' },
    {
      Header: 'Created On',
      accessor: 'createdOn',
      Cell: ({ value }) => formatDate(value),
    },
    { Header: 'Edit', accessor: '' },
  ]

  const [openModal, setOpenModal] = useState(false)
  const [singleRow, setSingleRow] = useState({})
  const [idToEdit, setIdToEdit] = useState('')
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [traineesNo, settraineesNo] = useState(false)
  const currentToken = cookies.get('access_token')
  const decoded = jwtDecode(currentToken)
  const role = decoded.role

  const dispatch = useDispatch()

  const fetchAllProjectsData = () => {
    dispatch(getAllClusters())
  }

  const fetchClusterByProjectId = () => {
    dispatch(getClusterByProject(projectId))
  }

  useEffect(() => {
    if (projectId) {
      fetchClusterByProjectId()
    } else {
      fetchAllProjectsData()
    }
  }, [dispatch, projectId])

  const { clusterData } = useSelector(state => state.clusterData)
  const { clusterViaProjectData } = useSelector(
    state => state.clusterViaProjectData
  )

  const GetTrainees = async id => {
    try {
      const currentToken = cookies.get('access_token')
      const config = {
        headers: {
          Authorization: 'Bearer ' + currentToken,
          source: 'HumblebeeWebApp',
        },
      }

      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_FIELD_URL}/clusters/trainees/${id}`,
        config
      )

      return res
    } catch (error) {
      console.log('Error in getting Project data:', error)
      throw error
    }
  }
  const fetchDataForIds = async clusterData => {
    try {
      const results = await Promise.all(
        clusterData.map(id => GetTrainees(id._id))
      )
      const trainingsArray = results.map(result => result.data.trainees)
      settraineesNo(trainingsArray)
      console.log(trainingsArray)
    } catch (error) {
      console.error('Error fetching data for clusterData:', error)
    }
  }

  useEffect(() => {
    if (projectId) {
      if (clusterViaProjectData?.length > 0) {
        console.log(clusterViaProjectData)
        fetchDataForIds(clusterViaProjectData)
      }
    } else {
      if (clusterData?.length > 0) {
        console.log(clusterData)
        fetchDataForIds(clusterData)
      }
    }
  }, [clusterData, clusterViaProjectData])

  const columns = useMemo(() => {
    const newColumns = [...Project_TABLE_COLUMNS]
    // newColumns[0] = {
    //   Header: "S.No",
    //   accessor: (_, index) => `P${index + 1}`,
    // };
    return newColumns
  }, [])

  const data = useMemo(() => {
    // If clusterData is empty, use clusterViaProjectData
    return clusterData.length > 0 && !projectId
      ? clusterData
      : clusterViaProjectData || []
  }, [clusterData, clusterViaProjectData])

  const tableInstance = useTable({ columns, data }, useSortBy, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance

  const { pageIndex } = state

  const handleModalToggle = () => {
    setOpenModal(prev => !prev)
  }

  const handleOpenEditModal = (id, singleRow) => {
    console.log('singleRow', singleRow)
    setSingleRow(singleRow)
    setIdToEdit(id)
    setOpenEditModal(true)
  }

  const handleOpenDeleteModal = id => {
    setDeleteModal(true)
    setIdToDelete(id)
  }


  return (
    <Styles.SupplierBox>
      <Styles.SupplierTopSection>
      {userData.userType.name != 'Funder' ? (
        <Styles.ModalButton onClick={handleModalToggle}>
          Add Cluster
        </Styles.ModalButton>
      ) : null}
        {openModal && (
          <ProjectForm openModal={openModal} setOpenModal={setOpenModal} />
        )}
      </Styles.SupplierTopSection>

      <Styles.TableContainer>
        {clusterData || clusterViaProjectData ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map(headerGroup => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(headerColumn => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render('Header')}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? ' 🔼'
                            : ' 🔽'
                          : ''}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <Styles.TBodyRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <Styles.TD key={cell.column.id}>
                          {cell.column.Header === 'Edit' && (
                            <button
                              disabled={userData.accountType != 'Super User'}
                              onClick={() =>
                                handleOpenEditModal(
                                  cell?.row?.original?._id,
                                  cell?.row.original
                                )
                              }
                            >
                              <img
                                style={{ height: '30px', width: '30px' }}
                                src={editIcon}
                                alt="Edit"
                              />{' '}
                            </button>
                          )}
                        
                            <>
                        
                              {cell.column.Header === 'Number Of Trainee' ? (
                                <Styles.ActionSpan>
                                  <Link
                                    to={`/leads?filterbycluster=${cell.row.original._id}`}
                                  >
                                    {traineesNo
                                      ? traineesNo[index]?.length
                                      : '-'}
                                  </Link>
                                  {role === 'Admin' && (
                                    <button
                                      onClick={() =>
                                        handleOpenDeleteModal(row.original._id)
                                      }
                                      title="Delete Project"
                                    >
                                      <img src={deleteIcon} alt="Delete" />
                                    </button>
                                  )}
                                </Styles.ActionSpan>
                              ) : (
                                cell.render('Cell')
                              )}
                            </>
                          
                        </Styles.TD>
                      )
                    })}
                  </Styles.TBodyRow>
                )
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>No projects to display</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            Previous
          </Styles.PaginationButton>
          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
            />
          </Styles.PaginationInputDiv>
          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            Next
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>

      {openEditModal && (
        <EditProjectForm
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}          
        />
      )}
      {openDeleteModal && (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      )}
    </Styles.SupplierBox>
  )
}

export default Clusters
